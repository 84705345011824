import { useDebounceEffect, useFavicon } from 'ahooks';
import { message } from 'antd';
import { FC, lazy } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import paths from '../../paths.json';
import { useOrganizationSettings } from '../OrganizationProvider';

const Auth = lazy(() => import('../../routes/Auth'));
const Home = lazy(() => import('../../routes/Home'));
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const Welcome = lazy(() => import('../../routes/Welcome'));

const App: FC = () => {
  const { pathname } = useLocation();
  const { brandmarkUrl } = useOrganizationSettings();

  useDebounceEffect(
    () => {
      message.destroy();
    },
    [pathname],
    { wait: 500 },
  );

  useFavicon(brandmarkUrl as string);

  return (
    <Switch>
      <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
      <Route path={[paths.login, paths.changePassword, paths.resetPassword]}>
        <Welcome />
      </Route>
      <Route path={paths.auth}>
        <Auth />
      </Route>
      <Route path={paths.home}>
        <Home />
      </Route>
    </Switch>
  );
};

App.displayName = 'App';

export default App;
